import {
  AdminSvg,
  BookKeepingSvg,
  // LawSvg,
  TaxSvg,
  HCMSvg,
  HandshakingSvg,
} from '../PFSWhoWeAreContent/Svg';

// Accordion sections
export const sections = [
  {
    Icon: AdminSvg,
    title: 'Administration Team',
    department: 'Admin',
  },
  {
    Icon: HandshakingSvg,
    title: 'Industry Partners',
    department: 'IndustryPartner',
  },
  {
    Icon: BookKeepingSvg,
    title: 'Financial Operations',
    department: 'FinOps',
  },
  {
    Icon: TaxSvg,
    title: 'Tax Operations',
    department: 'Tax',
  },
  {
    Icon: HCMSvg,
    title: 'People Operations',
    department: 'PeopleOps',
  },
  // {
  //   Icon: LawSvg,
  //   title: 'PFS Law, APC Team',
  //   department: 'Legal',
  // },
];
