import React from 'react';
import styles from './PFSServicesBanner.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSWaveContainer from '../PFSWaveContainer/PFSWaveContainer';
import PFSServiceCard from '../PFSServiceCard/PFSServiceCard';
import {
  PayrollSvg,
  BookKeepingSvg,
  TaxSvg,
} from '../PFSWhoWeAreContent/Svg';

const PFSServicesBanner = () => (
  <div className={styles.PFSServicesBanner} data-testid="PFSServicesBanner">
    <PFSWaveContainer>
      <ContentContainer>
        <p id={styles.TitleText}>
          <span id={styles.CompanyTitle}>PFS Global</span>
          <br></br>
          <span id={styles.CompanySubtitle}>Solving for the Modern Law Firm.</span>
        </p>
        <div style={{textAlign: 'center', width: '100%', marginTop: '5em'}}>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={<BookKeepingSvg />}
              title={'Financial Operations'}
              url={'/what-we-do/FinOps'}
            />
          </div>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={<TaxSvg />}
              title={'Tax Operations'}
              url={'/what-we-do/TaxOps'}
            />
          </div>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={<PayrollSvg />}
              title={'People Operations'}
              url={'/what-we-do/PeopleOps'}
            />
          </div>
        </div>
      </ContentContainer>
    </PFSWaveContainer>
  </div>
);

PFSServicesBanner.propTypes = {};

PFSServicesBanner.defaultProps = {};

export default PFSServicesBanner;
