import React from 'react';
import styles from './PFSWhatWeDoTaxes.module.css';
import globalStyles from '../../styles/global.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import PFSServiceHeader from '../PFSServiceHeader/PFSServiceHeader';
import PFSServicePoints from '../PFSServicePoints/PFSServicePoints';
import PFSWhyChoosePFS from '../PFSWhyChoosePFS/PFSWhyChoosePFS';
import PFSWaveContainer from '../PFSWaveContainer/PFSWaveContainer';
import PFSMeetTheTeam from '../PFSMeetTheTeam/PFSMeetTheTeam';

import PFSServiceCard from '../PFSServiceCard/PFSServiceCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faFolderOpen,
  faMoneyBillTrendUp,
} from '@fortawesome/free-solid-svg-icons';

import taxFormImage from './taxes.jpg'
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { WhatWeDoBreadcrumb } from '../PFSWhatWeDoIndex/PFSWhatWeDoIndex';

export const WhatWeDoTaxesBreadcrumb = [
  WhatWeDoBreadcrumb,
  {
    label: 'Tax Operations',
    url: '/what-we-do/TaxOps',
  },
];
const PFSWhatWeDoTaxes = () => {
  useBreadcrumb(WhatWeDoTaxesBreadcrumb)
  const currYear = new Date().getFullYear();

  return (
    <div className={styles.PFSWhatWeDoTaxes} data-testid='PFSWhatWeDoTaxes'>
      <ContentContainer>
        <PFSServiceHeader subtitle='Tax Operations' title='Solving for the Modern Law Firm.' />
        <div className={globalStyles.IntroContainer}>
          <img alt='Tax Forms' src={taxFormImage} className={globalStyles.IntroImage} />
          <div className={globalStyles.IntroText}>
            <p>At PFS Global, our TaxOps team specializes in providing tax services for law firms, as well as individuals and businesses within the legal profession. From income tax preparation to strategic tax planning, we deliver comprehensive solutions to ensure compliance with federal, state, and local tax regulations.</p>
            <p>Our experienced professionals stay current with the latest tax laws and industry trends, offering accurate and timely advice to help your firm achieve its financial goals. Contact us today to learn more about how our tax services can benefit your law firm.</p>
          </div>
        </div>
        <PFSServicePoints 
          title='Income Tax Preparation' 
          points={[
            "Business Tax Preparation",
            "Individual Tax Preparation",
            "Tax Planning",
          ]}
        />
        <PFSServicePoints
          title='State and Local Tax (SALT)'
          points={[
            'Sales and Use Tax',
            'Secretary of State Filings',
            'County Business Property Statements',
            'City Business License Filings',
            'Affordable Care Act - 1095/1094 Processing',
          ]}
        />
      
        <PFSServicePoints
          title='Tax Resolution'
          points={[
            'Federal, State, and Local Tax Resolution',
            'Federal, State, and Local Audit Assistance',
            'Discovery',
            'Payment Plan Arrangements',
            'Current Non-Collectible',
            'Offer in Compromise',
          ]}
        />
      </ContentContainer>
      <PFSWhyChoosePFS />
      <PFSMeetTheTeam department='Tax' />
      <PFSWaveContainer color='#2D75A9'>
        <ContentContainer>
          <center>
            <p id={'#tools'} className={styles.TitleText}>
              Tax Tools
            </p>
            <p id={styles.SubtitleText}>
              Here is a list of tools and tips designed to help make your tax
              preparation a little easier
              <br />
              Get a jump start on your taxes with our {currYear} Tax Checklist and our{' '}
              {currYear} Tax Organizer
            </p>
          </center>
          <div id={styles.ServiceCardsContainer}>
            <div className={styles.ServiceCardContainer}>
              <PFSServiceCard
                icon={<FontAwesomeIcon size='4x' icon={faMoneyBillTrendUp} />}
                title={'Rates'}
                subtitle={'Tax'}
                url={'/what-we-do/TaxOps/tax-rate-tool'}
              />
            </div>
            <div className={styles.ServiceCardContainer}>
              <PFSServiceCard
                icon={<FontAwesomeIcon size='4x' icon={faCalendarDays} />}
                title={'Dates'}
                subtitle={'Due'}
                url={'/what-we-do/TaxOps/due-dates-tool'}
              />
            </div>
            <div className={styles.ServiceCardContainer}>
              <PFSServiceCard
                icon={<FontAwesomeIcon size='4x' icon={faFolderOpen} />}
                title={'Guides'}
                subtitle={'Record Retention'}
                url={'/what-we-do/TaxOps/tax-guides-tool'}
              />
            </div>
          </div>
        </ContentContainer>
      </PFSWaveContainer>
      <br />
      <br />
      <br />
      <br />
      <br />
      <PFSContactCta ctaText='Call us today!' />
    </div>
  );
};

PFSWhatWeDoTaxes.propTypes = {};

PFSWhatWeDoTaxes.defaultProps = {};

export default PFSWhatWeDoTaxes;
