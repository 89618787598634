import React from 'react';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import PFSServiceHeader from '../PFSServiceHeader/PFSServiceHeader';
import PFSServicePoints from '../PFSServicePoints/PFSServicePoints';
import PFSWhyChoosePFS from '../PFSWhyChoosePFS/PFSWhyChoosePFS';
import globalStyles from '../../styles/global.module.css';

import PFSMeetTheTeam from '../PFSMeetTheTeam/PFSMeetTheTeam';
import peopleOpsImage from './peopleOps.jpg'
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { WhatWeDoBreadcrumb } from '../PFSWhatWeDoIndex/PFSWhatWeDoIndex';
import { useLocation } from 'react-router-dom';

const PFSWhatWeDoPeopleOps = () => {
  const { pathname } = useLocation();

  useBreadcrumb([
    WhatWeDoBreadcrumb,
    {
      label: 'People Operations',
      url: pathname,
    },
  ]);
  return(
  <div data-testid='PFSWhatWeDoPeopleOps'>
    <ContentContainer>
      <PFSServiceHeader title='Solving for the Modern Law Firm.' subtitle='People Operations' />
      <div className={globalStyles.IntroContainer}>
        <img alt='A calculator' src={peopleOpsImage} className={globalStyles.IntroImage} />
        <div className={globalStyles.IntroText}>
          <p>At PFS Global, our PeopleOps team provides tailored payroll and HR solutions to meet the unique needs of law firms. From precise payroll processing to comprehensive HR compliance support, we simplify and streamline the management of your team’s operations.</p>
          <p>By trusting us with your PeopleOps needs, you can ensure that your employees are paid accurately, on time, and with confidence in your firm's compliance. Contact us today to learn how we can help optimize your payroll and HR processes.</p>
        </div>
      </div>
      <PFSServicePoints
        title='Payroll Processing'
        points={[
          'Time and Attendance Software: Web, Mobile, Time Clock',
          'Direct Deposit and Paper Check Processing',
          'Federal and All 50 States Tax Payments and Filings',
          'Retirement, Health Insurance Plan, Supplemental Plan Reporting',
          "Pay as You Go Workers' Compensation Reporting",
          'W2/W3 Processing',
        ]}
      />
      <PFSServicePoints
        title='Outsourced HR Manager'
        points={[
          'Employee Onboarding: new hire documents',
          'Employee Maintenance: maintenance documents, dispute resolution, benefits, management, reviews, trainings, file management',
          'Employee Offboarding: exit interviews, termination packages, separation documents',
        ]}
      />
      <PFSServicePoints
        title='Outsourced Recruiting Manager'
        points={[
          'Review job descriptions',
          'Review compensation analysis',
          'Review of exempt vs. non-exempt classifications',
          'Review of non-discretionary bonuses credited towards overtime',
          'Review California AB5 classifications (ABC Test) for independent contractor classifications',
          'Review qualification for statutory employee classifications',
          'Post job ads',
          'Review and send top resumes to company management',
          'Schedule interviews',
          'Reference checks',
          'Offer letters',
          'Background checks',
          'Schedule first date of employment',
        ]}
      />
    </ContentContainer>
    <PFSWhyChoosePFS />
    <PFSMeetTheTeam department='PeopleOps' />
    <br />
    <br />
    <br />
    <br />
    <br />
    <PFSContactCta ctaText='Call us today!' />
  </div>
)};

PFSWhatWeDoPeopleOps.propTypes = {};

PFSWhatWeDoPeopleOps.defaultProps = {};

export default PFSWhatWeDoPeopleOps;
