import React from 'react';
import ContentContainer from '../ContentContainer/ContentContainer';
import styles from './PFSWhatWeDoIndex.module.css';
import globalStyles from '../../styles/global.module.css';

import PFSServiceCard from '../PFSServiceCard/PFSServiceCard';
import {
  PayrollSvg,
  BookKeepingSvg,
  // LawSvg,
  TaxSvg,
} from '../PFSWhoWeAreContent/Svg';

import PFSContactCta from '../PFSContactCta/PFSContactCta';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';

export const WhatWeDoBreadcrumb = {
  label: 'What We Do',
  url: '/what-we-do'
};

const PFSWhatWeDoIndex = () => {
  useBreadcrumb([ WhatWeDoBreadcrumb ]);

  return (
    <div className={styles.PFSWhatWeDoIndex} data-testid='PFSWhatWeDoIndex'>
      <ContentContainer>
        <p id={styles.PageHeader} className={globalStyles.TitleText}>
          PFS Global
        </p>
        <p
          id={styles.PageSubtitle}
          className={[globalStyles.SubtitleText, globalStyles.Fade].join(' ')}
        >
          Solving for the Modern Law Firm
        </p>
        <div id={styles.ServiceCardsContainer}>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={<BookKeepingSvg />}
              title={'Financial Operations'}
              url={'/what-we-do/FinOps'}
            />
          </div>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={<TaxSvg />}
              title={'Tax Operations'}
              url={'/what-we-do/TaxOps'}
            />
          </div>
          <div className={styles.ServiceCardContainer}>
            <PFSServiceCard
              icon={<PayrollSvg />}
              title={'People Operations'}
              url={'/what-we-do/PeopleOps'}
            />
          </div>
        </div>
        <PFSContactCta ctaText="Call us today!" />
      </ContentContainer>
    </div>
  );
};

PFSWhatWeDoIndex.propTypes = {};

PFSWhatWeDoIndex.defaultProps = {};

export default PFSWhatWeDoIndex;
